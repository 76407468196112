import { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LOGO from "../images/apptern.svg"

const Header = ({ siteTitle }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <header className={isMobileMenuOpen ? "menu-open" : ""}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="header-wrp">
              <div className="logo">
                <Link to="/">
                  <img src={LOGO} alt="Appcent Designathon" width="200" />
                </Link>
              </div>
              <nav>
                <Link className="header-link" to="#hakkinda">
                  Hakkında
                </Link>
                <Link className="header-link" to="#program">
                  Program
                </Link>
                <Link className="header-link" to="#mentorlar">
                  Mentorlar
                </Link>
                <Link className="header-link" to="#sss">
                  SSS
                </Link>

                <Link className="header-link" to="#basvuru">
                  Başvuru
                </Link>
              </nav>
              <div
                className={`hamburger hamburger--collapse ${
                  isMobileMenuOpen ? "is-active" : ""
                }`}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner"></div>
                </div>
              </div>
            </div>
            <nav className={`mobile-links ${isMobileMenuOpen ? "shown" : ""}`}>
              <Link
                className="header-link"
                to="#hakkinda"
                onClick={() => setMobileMenuOpen(false)}
              >
                Hakkında
              </Link>
              <Link
                className="header-link"
                to="#program"
                onClick={() => setMobileMenuOpen(false)}
              >
                Program
              </Link>
              <Link
                className="header-link"
                to="#mentorlar"
                onClick={() => setMobileMenuOpen(false)}
              >
                Mentorlar
              </Link>
              <Link
                className="header-link"
                to="#sss"
                onClick={() => setMobileMenuOpen(false)}
              >
                SSS
              </Link>

              <Link
                className="header-link"
                to="#basvuru"
                onClick={() => setMobileMenuOpen(false)}
              >
                Başvuru
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
