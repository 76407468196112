import { Link } from "gatsby"
import React from "react"

import APPCENT from "../images/Appcent-Light.svg"
import APPCENT_DESIGN from "../images/Appcent-Design.svg"
import APPCENT_AKADEMI from "../images/Appcent-Akademi.svg"
import LINKEDIN from "../images/linkedin.svg"
import INSTAGRAM from "../images/instagram.svg"
import TWITTER from "../images/twitter.svg"
import YOUTUBE from "../images/youtube.svg"


const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-12 col-lg-2">
            <div className="logo-ctn">
              <div className="logo-link">
                <img src={APPCENT_DESIGN} alt="Appcent" className="logo" />
              </div>
              <div className="sm">
                <a
                  href="https://linkedin.com/company/appcentdesign/"
                  target="_blank"
                >
                  <img src={LINKEDIN} alt="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/appcentdesign/"
                  target="_blank"
                >
                  <img src={INSTAGRAM} alt="instagram" />
                </a>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-lg-2 ">
            <div className="logo-ctn">
              <a
                href="https://appcent.mobi"
                target="_blank"
                className="logo-link"
              >
                <img src={APPCENT} alt="Appcent" className="logo" />
              </a>
              <div className="sm">
                <a href="https://linkedin.com/company/appcent" target="_blank">
                  <img src={LINKEDIN} alt="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/appcenttech/"
                  target="_blank"
                >
                  <img src={INSTAGRAM} alt="instagram" />
                </a>
                <a
                  href="https://twitter.com/appcenttech"
                  target="_blank"
                >
                  <img src={TWITTER} alt="twitter" width={38} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 offset-lg-1">
            <div className="logo-ctn">
              <div className="logo-link">
                <img src={APPCENT_AKADEMI} alt="Appcent" className="logo" />
              </div>
              <div className="sm">
                <a
                  href="https://linkedin.com/company/appcent-akademi"
                  target="_blank"
                >
                  <img src={LINKEDIN} alt="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/appcentakademi/"
                  target="_blank"
                >
                  <img src={INSTAGRAM} alt="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/@appcentappcentakademi8481"
                  target="_blank"
                >
                  <img src={YOUTUBE} alt="youtube" width={42} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="copyright">
              Copyright © 2021 Appcent All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
